import { graphql } from "gatsby";
import * as React from "react"
import { useTranslation } from "react-i18next"
import DefaultLayout from "../../layout";

import * as styles from "../index.module.scss"
import TeamCard from "../../components/TeamCard";

const TeamPage = ({data}) => {
  const {t} = useTranslation();

  const team = data.allTeamJson.nodes.sort((a, b) => {
    return a.sort < b.sort ? -1 : (a.sort > b.sort ? 1 : 0);
  })

  return (
    <DefaultLayout title={t("our_team")}>
      <meta name="robots" content="noindex"></meta>
      <div className={styles.layoutBox}>
        <h1>{t("our_team")}</h1>
        
        <div className={styles.flexList}>
          {team.map((teamMember) => {
            return <TeamCard 
                      name={teamMember.name} 
                      imageData={teamMember.localImage.childImageSharp.gatsbyImageData}
                      position={teamMember.position}
                      url={teamMember.website} />
          })}
        </div>

      </div>
    </DefaultLayout>
  )
}

export default TeamPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allTeamJson {
      nodes {
        sort
        name
        position
        website
        internal {
          type
        }
        localImage {
          childImageSharp {
            gatsbyImageData(height: 280, width: 280, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
