import React from "react";
import PropTypes from 'prop-types';

import * as styles from "./TeamCard.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Globe } from "lucide-react";

const TeamCard = (props) => {
    const {t} = useTranslation()

    const image = getImage(props.imageData)

    return (
        <div className={styles.teamCard}>
            <GatsbyImage image={image} alt={t("profilePictureOf", {name: props.name})} width={280} height={280} placeholder="blurred" />
            <div className={styles.metadata}>
                <span className={styles.position}>{t(props.position)}</span>
                <span className={styles.name}>{props.name}</span>
                {/*<span className={styles.description}>{props.description}</span>*/}
                {props.url ? <a href={props.url} target="_blank" rel="noreferrer" className={styles.extLink}><Globe height="20px"/> {props.url}</a> : null}
            </div>
        </div>
    );
}

TeamCard.props = {
    "name": PropTypes.string.isRequired,
    "imageData": PropTypes.object.isRequired,
    "position": PropTypes.string.isRequired,
    "url": PropTypes.string
};

export default TeamCard;